import React, { Component } from 'react';
import io from 'socket.io-client';
import axios from 'axios';
import Player from './player/Player';
import Logo from './../components/logo/logo';
import './Main.scss';

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSourceType: props.config.dataSourceType,
            restUrl: props.config.restUrl,
            restPollInterval: props.config.restPollInterval,
            restPollingIntervalObj: null,
            wsUrl: props.config.wsUrl,
            dataUrl: props.config.dataUrl,
            playerData: props.config.proxyData.players,
            numberOfPlayersToDisplay: props.config.numberOfPlayersToDisplay
        }

        this.loadRestData = this.loadRestData.bind(this);
    }

    componentDidMount() {
        switch (this.state.dataSourceType) {
            case "rest":
                if (this.state.restPollInterval && this.state.restPollInterval >= 3000) {
                    const interval = setInterval(this.loadRestData, this.state.restPollInterval);
                    this.setState({ restPollingIntervalObj: interval });
                } else {
                    this.loadRestData();
                }
                break;
            case "ws":
                this.loadWSData();
                break;
            case "proxy":
                this.loadProxyData();
                break;

            default:
                console.log("default data source");
                break;
        }
    }

    componentWillUnmount() {
        if(this.state.restPollingIntervalObj){
            clearInterval(this.state.restPollingIntervalObj);
            this.setState({ restPollingIntervalObj: null });
        }
      }

    loadRestData() {
        axios.get(this.state.restUrl)
            .then(response => {
                this.setState({ playerData: response.data.players });
            })
            .catch(error => {
                console.error(error);
            });
    }

    loadProxyData() {
        this.setState({ playerData: this.props.config.proxyData.players });
    }

    loadWSData() {
        let socket = io(this.state.wsUrl);
        // socket.emit('get player data');

        socket.on('connect', () => {
            console.log('socket connected');
        });

        socket.on('player data', (data) => {
            this.setState({ playerData: data.players })
        })

        socket.on('disconnect', function () {
            console.log('socket disconnected');
        });
    }

    buildPlayerList(players) {
        let results = players.map((player, index) => {
            if (index < this.state.numberOfPlayersToDisplay) {
                return (
                    <Player key={index} name={player.uid} score={player.finalScore} place={index + 1}></Player>
                )
            } else {
                return null;
            }
        });

        return results;
    }

    render() {
        const playerList = this.buildPlayerList(this.state.playerData);
        return (
            <div className="Main">
                <Logo />
                <div className="content">
                    {playerList}
                </div>
            </div>
        )
    }
}

export default Main;
