import React, { Component } from 'react'
import PlayerPhoto from './../../components/player-photo/player-photo'
import './Player.scss'

class Player extends Component {

    getPlaceStyle(place) {
        const results = ["player-info-container"];
        switch (this.props.place) {
            case 1:
                results.push("first");
                break;
            case 2:
                results.push("second");
                break;
            case 3:
                results.push("third");
                break;
            default:
                break;
        }

        return results.join (' ');
    }

    render() {
        const cln = this.getPlaceStyle(this.props.place);

        return (
            <div className="player">
                <div className="player-photo-container">
                <PlayerPhoto place={this.props.place} /></div>
                <div className={cln}>
                <div className="name">{this.props.name}</div>
                {this.props.place <= 3 ? <div className="circle-award-icon"><img src="/img/award-circle.png" alt=""/></div>:<div className="circle-award-icon"></div>}
                <div className="score">{this.props.score}</div>
                </div>
            </div>
        )
    }

}

export default Player;