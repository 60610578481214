import React from 'react';
import Main from './containers/Main';
import './App.scss';

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Main config={window['runtimeConfig']} />
    </div>
  );
}

export default App;
