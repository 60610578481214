import React from 'react';
import PlaceCircle from './../../components/place-circle/place-circle';
import './player-photo.scss';

const PlayerPhoto = (props) => {

    return (
        <div className="player-photo">
            <div className="place-circle-container">
                <PlaceCircle place={props.place} />
            </div>
            <img src="/img/photo-default.png" alt="" />
        </div>
    )
}

export default PlayerPhoto;