import React from 'react';
import './place-circle.scss';

const PlaceCircle = (props) => {

    let cln = "place-circle";

    if (props.place <= 3) {
        cln = "place-circle top-scorer";
    } else {
        cln = "place-circle";
    }

    return (
        <div className={cln}>{props.place}</div>
    )
}

export default PlaceCircle;