import React from 'react';
import './logo.scss';

const Logo = (props) => {

    return (
        <div className="logo">
            <img src="/img/logo.png" alt=""/>
        </div>
    )
}

export default Logo;